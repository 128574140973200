<template>
  <div class="ViewPermissionDia">
    <el-dialog
      title="数据权限设置"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      width="700px"
      top="50px"
      @close="close"
    >
      <div class="checkbox" style="height:75px;">
        <div style="flex:1;">
          <div class="flex flex-jb" style="margin-bottom:5px">
            <div style="color: #333;font-size:16px;">小程序最高报价数据</div>
            <el-switch
              v-model="dataPermission.isShowMaxQuotePermission"
              @change="sinkingpagelog()"
            >
            </el-switch>
          </div>
          <div class="flex flex-jb" style="font-size: 12px; color: #777;">
            <span v-if="!dataPermission.isShowMaxQuotePermission"
              >已设置不可查看</span
            >
            <span v-else>已设置{{ staffTypeStr }}可查看</span>
            <el-button
              v-if="dataPermission.isShowMaxQuotePermission"
              type="success"
              size="mini"
              @click="intoSet()"
              >进入设置</el-button
            >
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <div style="text-align: left;color:#666666;font-size:14px;">
          最近修改：<span v-if="dataPermission.operatorView"
            >{{ dataPermission.operatorView.operatorName }}（{{
              dataPermission.operatorView.createTime
            }}）</span
          >
          <span v-else>--</span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="twoSureDiaShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="getDataPermission"
    >
      <div
        style="text-align: center;margin: 20px 0;color: #666;font-size:16px;"
      >
        {{ configSetTipText }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="twoSureDiaShow = false">取消</el-button>
        <el-button
          :loading="twoSureBtnLoading"
          type="primary"
          @click="twoSureSubmit"
          >确认</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="更改设置"
      :visible.sync="roleSetDiaShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="getDataPermission"
    >
      <div>选择可查看最高报价数据的角色:</div>
      <div style="margin-top:10px">
        <el-checkbox-group v-model="staffTypes">
          <el-checkbox
            :label="item.value"
            size="small"
            border
            v-for="item in staffTypeList"
            :key="item.value"
            >{{ item.label }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="roleSetDiaShow = false">取消</el-button>
        <el-button type="primary" :loading="roleSetDiaLoading" @click="roleSetDiaSure">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    merchantId: [String, Number],
  },
  data() {
    return {
      dataPermission: {},
      staffTypes: [],
      staffTypeList: [
        {
          value: "00",
          label: "创建人",
        },
        {
          value: "01",
          label: "子账号",
        },
        {
          value: "02",
          label: "报价师",
        },
      ],
      configSetType: "", // 配置类型
      twoSureDiaShow: false,
      configSetTipText: "",
      twoSureBtnLoading: false,
      roleSetDiaShow: false,
      roleSetDiaLoading: false
    };
  },

  created() {
    this.getDataPermission();
  },
  computed: {
    staffTypeStr() {
      let strArr = [];
      this.staffTypeList.forEach((item) => {
        if (
          this.dataPermission.canShowMaxQuoteStaffTypes.indexOf(item.value) > -1
        ) {
          strArr.push(item.label);
        }
      });
      return strArr.join("、");
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getDataPermission() {
      _api.getDataPermission({ merchantId: this.merchantId }).then((res) => {
        if (res.code === 1) {
          this.dataPermission = res.data;
        }
      });
    },
    intoSet() {
      this.roleSetDiaShow = true;
    },
    closetwoSureDia() {
      this.getDataPermission();
    },
    sinkingpagelog() {
      if (
        this.dataPermission.canShowMaxQuoteStaffTypes &&
        this.dataPermission.canShowMaxQuoteStaffTypes.length > 0
      ) {
        this.staffTypes = [...this.dataPermission.canShowMaxQuoteStaffTypes];
        this.configSetTipText = this.dataPermission.isShowMaxQuotePermission
          ? "是否确认开启该商户在小程序查看最高报价数据的功能？"
          : "是否确认关闭该商户在小程序查看最高报价数据的功能？";
        this.twoSureDiaShow = true;
      }else{
        this.staffTypes = []
        this.roleSetDiaShow = true;
      }
    },
    twoSureSubmit() {
      this.twoSureBtnLoading = true;
      let params = {
        merchantId: this.merchantId,
        isShowMaxQuotePermission: this.dataPermission.isShowMaxQuotePermission,
        canShowMaxQuoteStaffTypes: this.staffTypes,
        saveType: '01'
      };
      _api.updateDataPermission(params).then((res) => {
        if (res.code === 1) {
            this.$message.success(res.msg || "操作成功");
            this.twoSureDiaShow = false;
        }
      }).finally(() => {
        this.twoSureBtnLoading = false;
      });
    },
    roleSetDiaSure() {
        this.roleSetDiaLoading = true;
        let params = {
            merchantId: this.merchantId,
            isShowMaxQuotePermission: this.dataPermission.isShowMaxQuotePermission,
            canShowMaxQuoteStaffTypes: this.staffTypes,
            saveType: '01'
        };
        _api.updateDataPermission(params).then((res) => {
            if (res.code === 1) {
                this.$message.success(res.msg || "操作成功");
                this.roleSetDiaShow = false;
            }
        }).finally(() => {
            this.roleSetDiaLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.ViewPermissionDia {
  .flex-jb {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  /deep/.el-form-item {
    margin-bottom: 10px !important;
  }
  .red-tip {
    color: #ff687b;
    font-size: 14px;
    line-height: 14px;
  }
  .checkbox {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 30px;
    box-sizing: border-box;
    border-bottom: 1px solid #ccc;
    font-size: 14px;
  }
}
</style>
